<template>
	<div id="dialog">
		<h3 v-if="editMode">Edit {{ clusterType }}</h3>
		<h3 v-else>Create New {{ clusterType }}</h3>
		<ClusterEditForm id="cluster-edit-form" ref="ref_clusterEditForm" :params="params" @exit="doCancel" />
	</div>
</template>

<script>
// Vue
import { mapState } from 'pinia'

// Stores
import { useDialogStore } from '@/stores/DialogStore'

// Components
import ClusterEditForm from '@/views/Room/ClusterEditForm'

// Internal
import { titlefy } from '@/use/StringMagic'

export default {
	name: 'DialogClusterCreate',
	components: { ClusterEditForm },
	setup() {
		const dialogStore = useDialogStore()
		return { dialogStore }
	},
	computed: {
		// Pinia state
		...mapState(useDialogStore, ['params', 'doConfirm', 'doCancel']),
		// List / Room
		clusterType() {
			return this.params.type ? titlefy(this.params.type) : ''
		},
		// This dialog is used both to create and update a list/room.
		editMode() {
			return !!this.params.id
		},
	},
	mounted() {
		this.dialogStore.disableEnterKey()
	},
}
</script>

<style scoped lang="scss">
#dialog {
	// So let the user expand the textarea.
	width: auto;
	min-width: 2.5rem;
}
#cluster-edit-form:deep() textarea {
	max-width: calc(100vw - 1.2rem);
	max-height: calc(100vh - 4.02rem);
}
#dialog h3 {
	margin-bottom: 0.3rem;
}
</style>

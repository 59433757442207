<template>
	<form class="structured-form" @submit.prevent="submit">
		<!-- Title -->
		<div id="name-wrap" class="wrap">
			<FormText
				v-model="clusterTitle"
				:error="clusterTitleError"
				:label="clusterType + ' Title *'"
				:placeholder="clusterType + ' title'"
				preventKeys="[<>]"
				:maxlength="500"
				:autoFocus="true"
				@update="onUpdate"
				:helper="existingCluster ? `That ${this.params.type}` : null"
				@helperClick="goToExistingCluster"
			/>
		</div>

		<!-- Intro text (room only) -->
		<div v-if="params.type == 'room'" id="intro-wrap" class="wrap">
			<FormText
				v-model="roomIntro"
				type="textarea"
				label="Room Intro"
				placeholder="Optional room intro"
				preventKeys="[<>]"
				:maxlength="3000"
			/>
		</div>

		<!-- Private toggle -->
		<FormToggle v-model="isPrivate" label="Private" />
		<div v-if="isPrivate" id="private-note" class="small" v-html="privateClusterMsg(params.type)"></div>

		<p v-if="errorMsg" class="error-msg small">{{ errorMsg }}</p>

		<div>
			<FormButton :level="1" :wait="waitingSubmit" value="Submit" />
			<FormButton :level="2" value="Cancel" :wait="waitingCancel" @click="$emit('exit')" />
		</div>
	</form>
</template>

<script>
// This component is shared between the ClusterEdit page
// page and the DialogClusterCreate dialog.

// Stores
import { useMeStore } from '@/stores/MeStore'
import { useApiStore } from '@/stores/ApiStore'
import { usePageUserStore } from '@/stores/PageUserStore'

// Components
import FormText from '@/components/FormText'
import FormToggle from '@/components/FormToggle'
import FormButton from '@/components/FormButton'

// Internal
import flash from '@/use/FlashAlert'
import { titlefy } from '@/use/StringMagic'
import { privateClusterMsg } from '@/use/Constants'

export default {
	name: 'ClusterEditForm',
	components: { FormText, FormToggle, FormButton },
	props: {
		params: {
			type: Object,
			default: () => {
				return {
					// type --> room/list
					// id, name, intro, isPrivate --> Populates form when editing.
				}
			},
		},
	},
	emits: ['submit', 'exit'],
	setup() {
		const meStore = useMeStore()
		const apiStore = useApiStore()
		const clusterApi = apiStore.loadApi('cluster')
		const pageUserStore = usePageUserStore()
		return { privateClusterMsg, meStore, clusterApi, pageUserStore }
	},
	data() {
		return {
			// v-model values
			clusterTitle: this.params.name,
			roomIntro: this.params.intro,
			isPrivate: this.params.isPrivate,
			clusterTitleError: null,

			// When name is taken.
			existingCluster: null,

			// General error message
			errorMsg: null,

			// Wait state for the buttons
			waitingCancel: false,
			waitingSubmit: false,
		}
	},
	computed: {
		// This dialog is used both to create and update a cluster.
		createMode() {
			return !this.params.id
		},

		// Room / List
		// For lowercase we use params.type
		clusterType() {
			return this.params.type ? titlefy(this.params.type) : ''
		},
	},
	methods: {
		// trash - Note: the $emit('cancel') directly on cancel button seemed to cause double triggers, but can no longer reproduce it... 6/28/22
		// doCancel() {
		// 	console.log('- - -')
		// 	this.$emit('cancel')
		// },

		// Reset errors on edit.
		onUpdate() {
			this.clusterTitleError = undefined
			this.existingCluster = undefined
		},

		// Validate
		validate() {
			if (!this.clusterTitle) {
				this.clusterTitleError = 'Required'
				return false
			}
			return true
		},

		// When a cluster with this name already exists, you can open it.
		goToExistingCluster() {
			if (this.params.type == 'room') {
				window.open(`/room/${this.existingCluster.params.id}`)
			} else {
				window.open(`/${this.$myUsername}/${this.existingCluster.params.namePath}`)
			}
		},

		async submit() {
			this.errorMsg = null

			// Validate form (returns boolean)
			const valid = this.validate()
			if (!valid) return

			const nameChange = this.clusterTitle != this.params.name
			const introChange = this.roomIntro != this.params.intro
			const isPrivateChange = this.isPrivate != this.params.isPrivate

			// In edit mode, if you submit without changing any values we ignore it.
			if (!nameChange && !introChange && !isPrivateChange) return this.$emit('exit')

			// Trigger wait mode
			this.waitingSubmit = true

			const newData = {}
			if (nameChange) newData.name = this.clusterTitle
			if (introChange) newData.intro = this.roomIntro
			if (isPrivateChange) newData.isPrivate = this.isPrivate

			// Post to API
			const response = this.createMode
				? await this.clusterApi.create({
						type: this.params.type,
						owner: this.$myUserId,
						...newData,
				  })
				: await this.clusterApi.update({
						type: this.params.type,
						id: this.params.id,
						owner: this.$myUserId,
						...newData,
				  })

			const { status, data: newCluster, statusText } = response

			if (status == 200) {
				if (this.createMode) {
					// Cluster created
					const buttonRoute =
						this.params.type == 'room'
							? { name: 'Room', params: { id: newCluster.id } }
							: {
									name: 'UserList',
									params: { username: this.$myUsername, namePath: newCluster.namePath },
							  }

					// Update store (required for list link to work)
					this.meStore.loadMyClusters()

					flash(`${this.clusterType} sucsessfully created.`, {
						title: newCluster.name,
						type: 'success',
						button: `Open ${this.clusterType.toLowerCase()}`,
						buttonRoute,
						persistent: true,
					})

					this.$emit('exit')
				} else {
					// Success message.
					flash(`${this.clusterType} successfully updated.`, { type: 'success', expiration: 1500 })

					// Emit submit event with updated cluster data.
					this.$emit('submit', newCluster)
				}
			} else if (status == 409) {
				// Name taken
				this.clusterTitleError = 'already exists'
				this.waitingSubmit = false
				if (this.params.type == 'room') {
					this.existingCluster = { name: 'Room', params: { id: newCluster.id } }
				} else {
					this.existingCluster = { name: 'List', params: { namePath: newCluster.namePath } }
				}

				return
			} else {
				// Other error
				this.errorMsg = statusText
				this.waitingSubmit = false
				return
			}

			// Close dialog
			this.waitingSubmit = false
		},
	},
}
</script>

<style scoped lang="scss">
.structured-form {
	flex-direction: column;
}

// Smaller gap so error msg and helper link
// are deisplayed as a sentence:
// [This room] [already exists]
#name-wrap:deep .label-text span.error {
	margin-left: 0.03rem;
}

// This allows the textarea to be resized
// with the container following.
#name-wrap,
#name-wrap:deep() .wrap,
#intro-wrap,
#intro-wrap:deep() .wrap {
	width: 100%;
}
#intro-wrap:deep() textarea.form-elm {
	width: auto;
	min-width: 100%;
}

#private-note {
	// width: 100%; // This causes dialog to expand :(
	width: 2.5rem;
	padding: 0.2rem 0.2rem 0.1rem 0.2rem;
	background: $black-05;
	margin-bottom: 0.3rem;
}

.error-msg.small {
	width: 100%;
	margin-top: -0.1rem;
	margin-bottom: 0.2rem;
}

@media only screen and (max-width: $mobile) {
	#intro-wrap:deep() textarea.form-elm {
		min-width: 0;
	}
}
</style>
